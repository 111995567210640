/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

// import Header from "./header"
// import Footer from "./footer"
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
// import PropTypes from "prop-types"
import { Helmet } from "react-helmet";

// import '../assets/css/styles.css';

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GimbOWL: AI Camera-Man</title>
      </Helmet>
      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout
